<template>

<div></div>

</template>

<script>

export default {

	created: async function() {

		await this.$store.dispatch('logout')

		this.$router.push({
			name: 'Login'
		})

	}

}

</script>

<style scoped>

</style>
